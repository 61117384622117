<template>
  <div
    style="background-color: #2d2d2d; color: #bcbec2; padding-top: 5px; width: 100%; text-align: center; margin: auto">
    <a href="http://www.beian.miit.gov.cn"
       style="color: #bcbec2; margin: auto; padding-bottom: 15px; text-decoration:none">
      <span>
      晋ICP备2022002008号-1
      </span>
    </a>
    &nbsp; &nbsp; &nbsp;
    <!--    <a href="http://www.beian.miit.gov.cn" style="color: #bcbec2; margin: auto; text-decoration:none">-->
    <!--      <img src="https://citi-1308543285.cos.ap-shanghai.myqcloud.com/icp.png">-->
    <!--      <span>-->
    <!--      苏公网安备 32011302320916号-->
    <!--      </span>-->
    <!--    </a>-->
    &nbsp; &nbsp; &nbsp;
    <a href="/privacy" style="color: #bcbec2; text-decoration:none">使用与隐私条款<br></a>
    <div style="padding: 2px"></div>
    <a href="http://www.beian.miit.gov.cn" style="color: #bcbec2; text-decoration:none">Powered by NJU Text Analysts,
      2022</a>
    <!--    <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>-->

  </div>
</template>

<script>
export default {
  name: 'FooterLayoutSlim'
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;

:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
