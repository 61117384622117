<template>
  <div>
    <el-menu
      v-if="showTop"
      :default-active="activeHeader"
      active-text-color="#ffd04b"
      background-color="#34495E"
      class="topNavBar"
      mode="horizontal"
      router
      style="font-size: 1rem; "
      text-color="#fff"
    >

    <el-menu-item>
      <a href="/">
      <img src="https://citi-1308543285.cos.ap-shanghai.myqcloud.com/Textual%20ESG_%E7%94%BB%E6%9D%BF%201.png" height="50%" >
      </a>
    </el-menu-item>

      <el-menu-item index='/esgindex'>
        <span slot="title" style="font-size: larger">ESG指数</span>
      </el-menu-item>
      <el-menu-item index='/rank/indexbrowser'>
        <span slot="title" style="font-size: larger">ESG评级</span>
      </el-menu-item>
      <el-menu-item index='/invest'>
        <span slot="title" style="font-size: larger">ESG投资</span>
      </el-menu-item>


      <!-- <el-menu-item index='' @click="jumpToDocs()">
        <span slot="title" style="font-size: larger">文档</span>
      </el-menu-item>
      <el-menu-item index='/about'>
        <span slot="title" style="font-size: larger">关于</span>
      </el-menu-item> -->

      <!-- <el-menu-item style="float:right;display: none;">
        <el-avatar src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2F40%2Fc9%2F53%2F40c9533e47b9ce0945a2030f9320b80e.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1645533223&t=ab0e440ae9dc6efc987154a0afa3e496" style="height:40px;border-radius:50%;margin-right:0.5rem"/>用户</el-menu-item> -->
      <el-menu-item v-if=!status index="/login" style="float:right;" @click="login">
        <el-button round size="small"><i class="el-icon-user-solid"></i>登录</el-button>
      </el-menu-item>

      <el-submenu v-if=status index=10 style="float:right; font-size: larger">
        <template slot='title'>
          <i class="el-icon-setting" style="color: white;"></i>{{ this.$store.state.name }}
        </template>
        <el-menu-item index='/user'>个人信息</el-menu-item>
        <el-menu-item index="/combination">组合管理</el-menu-item>
        <el-menu-item index="/" @click="logout">退出登录</el-menu-item>
      </el-submenu>

      <el-menu-item index='/about' style="float: right;">
        <span slot="title" style="font-size: larger">关于</span>
      </el-menu-item>
      <el-menu-item index='' style="float: right;" @click="jumpToDocs()">
        <!-- <a href="http://doc.textualesg.com" style="font-size: larger">文档</a> -->
        <span slot="title" style="font-size: larger">文档</span>
      </el-menu-item>

      <!-- <el-menu-item style="float:right" :index="item.path" v-for="item in menu_right" :key="item.path">
          <span slot="title" style="font-size: larger">{{item.label}}</span>
      </el-menu-item> -->

    </el-menu>

    <el-menu
      v-else
      :default-active="activeHeader"
      active-text-color="#ffd04b"
      background-color="#34495E"
      class="topNavBar"
      mode="horizontal"
      router
      style="font-size: 1rem; "
      text-color="#fff">
      <el-menu-item>
        <a href="/">
          <img src="https://citi-1308543285.cos.ap-shanghai.myqcloud.com/logo.png" style="width: 106px;">
        </a>
      </el-menu-item>

      <el-submenu>
        <template slot="title">
          <i class="el-icon-s-grid" style="font-size:35px; color: white;"></i>
        </template>
        <el-menu-item index='/esgindex'>
          <span slot="title" style="font-size: medium">ESG指数</span>
        </el-menu-item>
        <el-menu-item index='/rank/indexbrowser'>
          <span slot="title" style="font-size: medium">ESG评级</span>
        </el-menu-item>
        <el-menu-item index='/invest'>
          <span slot="title" style="font-size: medium">ESG投资</span>
        </el-menu-item>
        <el-menu-item index='/about'>
          <span slot="title" style="font-size: medium">关于</span>
        </el-menu-item>
        <el-menu-item index='' @click="jumpToDocs()">
          <!-- <a href="http://doc.textualesg.com" style="font-size: larger">文档</a> -->
          <span slot="title" style="font-size: medium">文档</span>
        </el-menu-item>
      </el-submenu>

      <!-- <el-menu-item style="float:right;display: none;">
        <el-avatar src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2F40%2Fc9%2F53%2F40c9533e47b9ce0945a2030f9320b80e.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1645533223&t=ab0e440ae9dc6efc987154a0afa3e496" style="height:40px;border-radius:50%;margin-right:0.5rem"/>用户</el-menu-item> -->
      <el-menu-item v-if=!status index="/login" style="float:right;" @click="login">
        <el-button circle size="mini"><i class="el-icon-user-solid" style="font-size: small;"></i></el-button>
      </el-menu-item>


      <el-submenu v-if=status index=10 style="float:right; font-size: larger">
        <template slot="title">
          <i class="el-icon-setting" style="font-size:35px; color: white;"></i>
        </template>
        <el-menu-item index='/user'>个人信息</el-menu-item>
        <el-menu-item index="/combination">组合管理</el-menu-item>
        <el-menu-item index="/" @click="logout">退出登录</el-menu-item>
      </el-submenu>

    </el-menu>
  </div>
</template>
<script>

export default {

  data() {
    return {
      status: this.$store.state.isLogin,
      showTop: true,
      showSide: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.Switching)

  },
  methods: {
    login() {
      console.log(this.isLogin)
    },
    logout() {
      localStorage.clear()
      this.$store.commit('set', {Login: false})
      // this.$router.push('/')
      location.reload();
    },
    jumpToDocs() {
      window.open("https://doc.textualesg.com")
    },
    Switching() {
      let that = this
      let w = document.documentElement.clientWidth || document.body.clientWidth
      if (w > 800) {
        // PC端
        that.showTop = true
      } else {
        // 移动端 hui
        that.showTop = false
        that.showSide = false
      }
    }
  },
  computed: {
    activeHeader: function () {
      const {meta, path} = this.$route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  created() {
    this.Switching()
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}

/* .normalMenu{

}
.thinMenu{

} */

/* .el-menu{
  min-width: 40px!important;
} */

</style>
