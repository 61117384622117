<template>
  <div class="home" style="height: auto">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main>
        <div style="text-align: left">
          <h1>Textual使用与隐私条款</h1>
          <h2>使用条款</h2>
          <p>欢迎来到TextualESG。</p>
          <p>
            请您仔细阅读一下条款，如果您对本条款表示异议，您可以选择不进入TextualESG。当您注册成功，无论是进入TextualESG（下称"我们"），还是在本网站上配置资产方案，均意味着您（即「用户」）完全接受本协议项下的全部条款。
          </p>
          <p>
            1.用户注册成功后，我们将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
          </p>
          <p>
            2.用户须对在本网站上的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得恶意使用注册帐号导致其他用户误认；否则我们有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
          </p>
          <p>
            3.我们有权对用户使用本网站的情况进行审查和监督，如用户在使用知乎时违反任何上述规定，本站或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于暂停或终止用户使用本站的权利）以减轻用户不当行为造成的影响。
          </p>
          <h2>隐私条款</h2>
          <p>
            TextualESG平台的运营者，本《隐私政策》（以下简称「本政策」）阐述了TextualESG（下称"我"或者"我们"）将如何处理您的个人信息和隐私信息，并申明了我们对保护隐私的承诺，我们未来有可能根据信息处理情境不时更新或修改本政策。请您在向我们提交个人信息或隐私信息之前，阅读、了解并同意本政策和任何补充政策。
          </p>
          <p>
            本隐私政策要点如下：
          </p>
          <p>
            - 我们会逐一说明我们对个人信息收集、使用、保护等处理的情况，以便您能够了解个人信息的概况。
          </p>
          <p>
            - 当您注册帐号和使用我们的服务时，我们会根据您的同意和提供服务的需要，收集您的姓名、性别、年龄、个人资料照片或视频、电话号码、电子邮件、社交帐号、身份验证信息、位置信息和日志信息等个人信息。
          </p>
          <p>
            -
            目前，除法律法规、法律程序、诉讼或政府主管部门强制性要求外，我们不会主动公开披露您的个人信息，如果存在其他需要公开披露个人信息的情形，我们会征得您的明示同意。同时，我们保证披露采取符合法律和业界标准的安全防护措施。
          </p>
          <p>
            - 您可以通过本隐私政策所列的途径访问、更正或删除您的个人信息，也可以进行隐私设置或与我们取得联系。
          </p>
          <p>
            您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存、共享、转让和公开披露您的相关信息。
          </p>
          <h3>
            1. 我们收集的个人信息
          </h3>
          <p>
            -
            注册信息。您使用我们提供的服务，可以注册并登录经注册的帐号。此时，您需要向我们提供以下信息：帐号名称、头像（如有）和邮箱等。提供上述信息并同意注册协议和本政策后，您可以使用我们的核心业务功能，包括：浏览平台内的内容、创建账户等。在您注册帐号时，我们会使用邮箱对您进行验证。
          </p>
          <p>
            -
            附加信息。当您使用我们的附加业务功能时，为满足向您提供该产品和服务之目的，除注册信息外，您还需要进一步向我们提供包括但不限于您的个人身份信息、位置信息及其他为您提供服务所必需的信息，如果您不使用特定产品和服务，则无需提供相关信息。
          </p>
          <h3>
            2. 我们会如何转让、公开披露您的个人信息
          </h3>
          <p>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </p>
          <p>
            - 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
          </p>
          <p>
            - 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
          </p>
          <p>
            我们仅会在以下情况下，且采取符合法律和业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
          </p>
          <p>
            - 获得您的明确同意；
          </p>
          <p>
            - 基于法律法规、法律程序、诉讼或政府主管部门强制性要求。但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件。
          </p>

        </div>

      </el-main>
    </el-container>
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
// @ is an alias to /src

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'

export default {
  name: 'textualESG',
  components: {
    FooterLayout,
    HeaderLayout
  }
}
</script>

<style>
.el-header {
  background-color: #34495E;
  color: #333;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
