<template>
  <div
    style="background-color: #2d2d2d; color: #bcbec2; padding-top: 5px; width: 100%; text-align: center; margin: auto; overflow: hidden">
    <el-row :gutter="20" style="padding-left: 5%; padding-right: 5%">
      <el-col :span=colWidth>
        <div class="grid-content">
          <div style="text-align: left; padding: 1px">
            <!--            <a href="/" style="color: #bcbec2; margin: 0 auto; padding: 0; border: 0">-->
            <!--              <img src="https://citi-1308543285.cos.ap-shanghai.myqcloud.com/logo.png" style="width: 50%" >-->
            <!--              &lt;!&ndash;            <p>TextAnalyst<br/><span>基于文本信息的ESG投资系统</span></p>&ndash;&gt;-->
            <!--            </a>-->
            <h1>TextualESG</h1>
            <p style="font-size: large">
              基于文本信息的ESG投资系统
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span=colWidth>
        <div class="grid-content">
          <div style="padding: 1px; text-align: left">
            <h2 style="text-align: left">
              <font-awesome-icon icon="book" size="lg"/>
              郑重声明
            </h2>
            <p>
              本项目为2021-2022年第十七届<br>
              “花旗杯”金融创新应用大赛参赛作品。<br>
              网站上所有信息均不作为正式投资建议。
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span=colWidth>
        <div class="grid-content">
          <div style="padding: 1px; text-align: left">
            <h2 style="text-align: left">
              <font-awesome-icon icon="blender-phone" size="lg"/>
              联系我们
            </h2>
            <p>
              <font-awesome-icon icon="envelope-open-text"/>
              <a href="mailto: textualesg@163.com" style="color: #bcbec2">textualesg@163.com</a>
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span=colWidth>
        <div class="grid-content">
          <div style="padding: 1px; text-align: left">
            <h2 style="text-align: left">
              <font-awesome-icon icon="map-marker-alt" size="lg"/>
              地址
            </h2>
            <p>
              江苏省南京市鼓楼区平仓巷5号<br/>
              南京大学工程管理学院
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <FooterLayoutSlim></FooterLayoutSlim>
  </div>

</template>

<script>
import FooterLayoutSlim from './FooterLayoutSlim'

export default {
  name: 'FooterLayout',
  components: {FooterLayoutSlim},
  data() {
    return {
      colWidth: 6
    }
  },
  methods: {
    calcSize(that) {
      if (window.innerWidth < 800) {
        that.colWidth = 12;
      } else {
        that.colWidth = 6;
      }
    }
  },
  mounted() {
    const that = this;
    this.calcSize(this);
    window.onresize = () => {
      return (() => {
        that.calcSize(that);
      })()
    }
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

h1 {
  font-size: xx-large;
}

h2 {
  font-size: x-large;
}

</style>
